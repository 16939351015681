// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log('HMR')
	})
}

import 'lazysizes'
import './address-field.js'

import '/src/sass/app.scss'

import Alpine from 'alpinejs'

import persist from '@alpinejs/persist'

// import { initDealerLocation } from './dealerLocation.js'
// initDealerLocation()

Alpine.plugin(persist)

window.Alpine = Alpine

Alpine.start()
